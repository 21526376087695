import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const signOutUser = async () => {
            try {
                await signOut(auth);
                console.log('User signed out');
            } catch (error) {
                console.error('Error signing out:', error);
            }
        };
        signOutUser();
    }, [auth]);

    const checkIfAdmin = async (email) => {
        try {
            const adminDocRef = doc(db, 'admins', email);
            const adminDocSnap = await getDoc(adminDocRef);
            return adminDocSnap.exists();
        } catch (error) {
            console.error('Error checking admin status:', error);
            return false;
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const userEmail = userCredential.user.email;
            console.log('Logged in:', userEmail);

            const isAdmin = await checkIfAdmin(userEmail);
            if (isAdmin) {
                navigate('/admin');
            } else {
                navigate('/portail');
            }
        } catch (error) {
            console.error('Login error:', error);
            let errorMessage = '';
            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = 'Adresse e-mail invalide.';
                    break;
                case 'auth/user-disabled':
                    errorMessage = "L'utilisateur a été désactivé.";
                    break;
                case 'auth/user-not-found':
                    errorMessage = "Utilisateur non trouvé.";
                    break;
                case 'auth/wrong-password':
                    errorMessage = 'Mot de passe incorrect.';
                    break;
                case 'auth/invalid-credential':
                    errorMessage = ' Email ou Mot de passe incorrect.';
                    break;
                default:
                    errorMessage = 'Une erreur est survenue. Veuillez réessayer.';
            }
            setError(errorMessage);
        }
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center min-vh-100 pb-5" style={{ backgroundColor: '#20263E' }}>
            <div className="text-center mb-4">
                <img src="/images/white-transparent-bg.png" alt="ITIBAR & Avocats Associés" className="mb-3" />
                <h2 className="text-white">Bienvenue sur le Portail Client du Cabinet!</h2>
                <p className="text-white mx-auto" style={{ maxWidth: '500px' }}>
                    Pour accéder à l'intégralité de votre dossier client, veuillez vous
                    connecter à l'aide de l'identifiant et du mot de passe que nous vous
                    avons fournis.
                </p>
            </div>
            <form onSubmit={handleLogin} className="bg-dark p-4 rounded shadow" style={{ width: '400px' }}>
                <div className="form-group mb-3">
                    <label htmlFor="email" className="text-white">Identifiant</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Identifiant"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="password" className="text-white">Mot de passe</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                <button type="submit" className="btn btn-primary w-100">Se connecter</button>
            </form>
        </div>
    );
};

export default Login;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';

const Admin = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deletingUser, setDeletingUser] = useState(''); // New state for tracking the user being deleted

    useEffect(() => {
        const fetchUsers = async () => {
            const db = getFirestore();

            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef);
                const querySnapshot = await getDocs(q);

                const fetchedUsers = querySnapshot.docs.map(doc => ({
                    email: doc.id,
                    ...doc.data()
                }));

                setUsers(fetchedUsers);
            } catch (error) {
                setError('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleDeleteUser = async (email) => {
        if (!window.confirm(`Êtes vous sûr de vouloir supprimer ${email}?`)) {
            return;
        }

        setDeletingUser(email); // Set the email of the user being deleted

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-user`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Échec de la suppression de l\'utilisateur.');
            }

            setUsers(users.filter(user => user.email !== email));
            alert(`User ${email} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('Échec de la suppression de l\'utilisateur. Veuillez réessayer.');
        } finally {
            setDeletingUser(''); // Clear the loading state after the operation is complete
        }
    };

    const filteredUsers = users.filter(user =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container mt-4 text-white">
            <Link to="/admin/publications">
                <button className="btn btn-secondary mb-5 me-3">
                    Voir les publications
                </button>
            </Link>

            <Link to="/admin/create-user">
                <button className="btn btn-secondary mb-5 me-3">
                    Créer un nouvel utilisateur
                </button>
            </Link>

            <Link to="/admin/admins">
                <button className="btn btn-secondary mb-5">
                    Administrateurs
                </button>
            </Link>

            <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h2 className="mb-0">Liste des Utilisateurs</h2>
                    <p className="mb-0">Cliquez sur un utilisateur pour voir ses affaires.</p>
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="search" className="mr-2 align-self-center mb-0">Recherche par e-mail</label>
                    <input
                        type="text"
                        id="search"
                        className="form-control"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="E-mail"
                    />
                </div>
            </div>

            {loading && <p>Chargement...</p>}
            {error && <p>{error}</p>}

            {!loading && !error && (
                <table className="table table-dark my-table table-borderless">
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>E-mail</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredUsers.map((user) => (
                        <tr key={user.email}>
                            <td>{user.name}</td>
                            <td>
                                <Link
                                    to={`/admin/portail/${user.email}`}
                                    style={{ color: '#1F3D5F', fontWeight: 'bold' }}
                                >
                                    {user.email}
                                </Link>
                            </td>
                            <td>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteUser(user.email)}
                                    disabled={deletingUser === user.email} // Disable the button if the user is being deleted
                                >
                                    {deletingUser === user.email ? 'Suppression...' : 'Supprimer'}
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Admin;

import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Navbar from './Navbar';
import Footer from './Footer';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [contactDetails, setContactDetails] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [emailError, setEmailError] = useState(null);
    const [sending, setSending] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true);
        try {
            const templateParams = {
                name: contactDetails.name,
                email: contactDetails.email,
                phone: contactDetails.phone,
                message: contactDetails.message,
            };

            await emailjs.send(
                'service_7lz79yj',
                'template_96gkisf',
                templateParams
            );

            console.log('Email sent successfully');
            setContactDetails({ name: '', email: '', phone: '', message: '' });
        } catch (error) {
            console.error('Failed to send email:', error);
            setEmailError('Failed to send email notification');
        } finally {
            setSending(false);
        }
    };

    return (
        <div className="contact">
            <Navbar active="contacts" />
            <div className="contact-container mx-5  main-content">
                <h2 className="contact-title pt-5">Contactez-nous</h2>
                <Row>
                    <Col md={6} className="d-none d-md-block">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1388.016662764798!2d43.260427940119705!3d-11.692006117917987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1898fc1ac67e4367%3A0x2e2695854681bd5c!2sITIBAR%20%26%20AVOCATS%20ASSOCIES!5e0!3m2!1sfr!2smg!4v1723793961055!5m2!1sfr!2smg"
                            width="100%"
                            height="450"
                            style={{ border: '0' }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Col>
                    <Col md={6}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="name">
                                <Form.Label>Nom et prénom</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={contactDetails.name}
                                    onChange={handleInputChange}
                                    placeholder="Entrez votre nom et prénom"
                                    required
                                />
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={contactDetails.email}
                                            onChange={handleInputChange}
                                            placeholder="Entrez votre email"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>Numéros de téléphone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            value={contactDetails.phone}
                                            onChange={handleInputChange}
                                            placeholder="Entrez votre numéro de téléphone"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group controlId="message">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    value={contactDetails.message}
                                    onChange={handleInputChange}
                                    rows={3}
                                    placeholder="Votre message"
                                    required
                                />
                            </Form.Group>

                            {emailError && <p className="text-danger">{emailError}</p>}

                            <Button type="submit" className="mt-3 Soumettre" disabled={sending}>
                                {sending ? 'Envoi...' : 'Soumettre'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
            <Footer active="contact" />
        </div>
    );
};

export default Contact;

import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {getFirestore, collection, getDocs, deleteDoc, doc} from 'firebase/firestore';
import {Button, Container, Table, Spinner, Alert} from 'react-bootstrap';

const AdminPublications = () => {
    const [publications, setPublications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPublications = async () => {
            const db = getFirestore();

            try {
                const publicationsRef = collection(db, 'publications');
                const querySnapshot = await getDocs(publicationsRef);

                const fetchedPublications = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setPublications(fetchedPublications);
            } catch (error) {
                setError('Échec de la récupération des publications');
            } finally {
                setLoading(false);
            }
        };

        fetchPublications();
    }, []);

    const handleDelete = async (id) => {
        const db = getFirestore();

        try {
            await deleteDoc(doc(db, 'publications', id));
            setPublications(publications.filter(pub => pub.id !== id));
        } catch (error) {
            setError('Échec de la suppression de la publication');
        }
    };

    return (
        <Container className="mt-4 text-white">
            <Button
                variant="secondary"
                className="mb-5"
                onClick={() => navigate('/admin/create-publication')}
            >
                Créer une nouvelle publication
            </Button>

            {loading && <Spinner animation="border" variant="light"/>}
            {error && <Alert variant="danger">{error}</Alert>}

            {!loading && !error && (
                <table className='table table-dark my-table table-borderless'>
                    <thead>
                    <tr>
                        <th>Titre</th>
                        <th>Description</th>
                        {/*<th>Contenu</th>*/}
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {publications.map(pub => (
                        <tr key={pub.id}>
                            <td>{pub.title}</td>
                            <td>
                                {/*<img src={pub.preview} alt={pub.title} style={{ width: '100px', height: 'auto' }} />*/}
                                {pub.preview}
                            </td>
                            {/*<td>{pub.content}</td>*/}
                            <td>
                               <Link to={`/admin/edit-publication/${pub.id}`}>
                                    <Button variant="dark" className="me-2">Modifier</Button>
                                </Link>
                                <Button variant="danger" onClick={() => handleDelete(pub.id)}>Supprimer</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </Container>
    );
};

export default AdminPublications;

import React from 'react';
import './Footer.css'; // Make sure to include the CSS file

const Footer = ({ active }) => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Row 1: Social Media Icons */}
                <div className="footer-icons">
                    <a href="https://wa.me/2694458714" target="_blank" rel="noopener noreferrer">
                        <img src="/images/phone-icon.png" alt="WhatsApp" className="footer-icon" />
                    </a>
                    <a href="https://km.linkedin.com/company/itibar-avocats-associes" target="_blank" rel="noopener noreferrer">
                        <img src="/images/linkedin-icon.png" alt="LinkedIn" className="footer-icon" />
                    </a>
                    <a href="https://m.youtube.com/@iaa-avocats" target="_blank" rel="noopener noreferrer">
                        <img src="/images/youtube-icon.png" alt="YouTube" className="footer-icon" />
                    </a>
                </div>

                {/* Row 2: Navigation Links */}
                <div className="footer-links">
                    <a href="/cabinet" className={`footer-link ${active === 'le-cabinet' ? 'active' : ''}`}>LE CABINET</a>
                    <a href="/publications" className={`footer-link ${active === 'publications' ? 'active' : ''}`}>PUBLICATIONS</a>
                    <a href="/contacts" className={`footer-link ${active === 'contacts' ? 'active' : ''}`}>CONTACTS</a>
                    <a href="/rendez-vous" className={`footer-link ${active === 'rdv' ? 'active' : ''}`}>PRENDRE RENDEZ-VOUS</a>
                    <a href="/login" className={`footer-link ${active === 'espace-client' ? 'active' : ''}`}>ESPACE CLIENT</a>
                </div>

                {/* Row 3: Footer Image */}
                <div className="footer-image">
                    <img src="/images/black-horizontal-transparent-bg.png" alt="Footer" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;

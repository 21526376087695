import React, { useEffect, useState } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function FinishSignUp() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const finishSignUp = async () => {
            const url = window.location.href;
            if (isSignInWithEmailLink(auth, url)) {
                const email = window.localStorage.getItem('emailForSignUp');
                if (email) {
                    try {
                        const result = await signInWithEmailLink(auth, email, url);
                        window.localStorage.removeItem('emailForSignUp');

                        // Check if the user is an admin
                        const userDoc = await getDoc(doc(db, 'admins', email));
                        if (userDoc.exists()) {
                            // Call server to set custom claim
                            await fetch(`${process.env.REACT_APP_BACKEND_URL}/set-admin`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ email: email }),
                            });
                        }

                        // Pass the email to the SetPassword component
                        navigate('/set-password', { state: { email } });
                    } catch (error) {
                        console.error('Error completing sign-up:', error);
                        setError('Une erreur est survenue lors de l’inscription.');
                    }
                } else {
                    setError('Email non trouvé.');
                }
            }
            setLoading(false);
        };

        finishSignUp();
    }, [auth, navigate]);

    return (
        <div className="container text-center my-5">
            <h1 className="text-white">Finalisation de l'inscription</h1>
            {loading ? (
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </div>
            ) : (
                error && <div className="alert alert-danger mt-3" role="alert">{error}</div>
            )}
        </div>
    );
}

export default FinishSignUp;

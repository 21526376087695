import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import Login from './views/auth/Login';
import Portail from './views/Portail';
import Ecritures from './views/Ecritures';
import Admin from './views/admin/Admin';
import AdminPortal from './views/admin/AdminPortal';
import AdminEcritures from './views/admin/AdminEcritures';
import CreatePublication from './views/admin/CreatePublication';
import EditPublication from './views/admin/EditPublication';
import AdminPublications from './views/admin/AdminPublications';
import FinishSignUp from "./views/FinishSignup";
import SetPassword from "./views/SetPassword";
import CreateUser from "./views/admin/CreateUser";
import CreateCase from "./views/admin/CreateCase";
import ListAdmins from "./views/admin/ListAdmins";
import CreateAdmin from "./views/admin/CreateAdmin";
import EditCase from "./views/admin/EditCase";
import Home from "./views/home/Home";
import Cabinet from "./views/home/Cabinet";
import Contact from "./views/home/Contact";
import Publications from "./views/home/Publications";
import PublicationDetail from "./views/home/PublicationDetail";
import RDV from "./views/home/RDV";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/finishSignUp" element={<FinishSignUp />} />
                <Route path="/set-password" element={<SetPassword />} />

                <Route path="/cabinet" element={<Cabinet />} />
                <Route path="/contacts" element={<Contact />} />
                <Route path="/publications" element={<Publications />} />
                <Route path="/publications/:id" element={<PublicationDetail />} />
                <Route path="/rendez-vous" element={<RDV />} />
                <Route
                    path="*"
                    element={
                        <Layout>
                            <Routes>
                                <Route path="/portail" element={<Portail />} />
                                <Route path="/ecritures/:id" element={<Ecritures />} />
                                <Route path="/admin" element={<Admin />} />
                                <Route path="/admin/portail/:email" element={<AdminPortal />} />
                                <Route path="/admin/ecritures/:email/:id" element={<AdminEcritures />} />
                                <Route path="/admin/create-case/:email" element={<CreateCase />} />
                                <Route path="/admin/edit-case/:email/:caseId" element={<EditCase />} />
                                <Route path="/admin/create-user" element={<CreateUser />} />
                                <Route path="/admin/create-publication" element={<CreatePublication />} />
                                <Route path="/admin/edit-publication/:id" element={<EditPublication />} />
                                <Route path="/admin/publications" element={<AdminPublications />} />
                                <Route path="/admin/admins" element={<ListAdmins />} />
                                <Route path="/admin/create-admin" element={<CreateAdmin />} />
                                <Route path="*" element={<Navigate to="/login" />} />
                            </Routes>
                        </Layout>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;

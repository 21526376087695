import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Container, Spinner, Alert } from 'react-bootstrap';
import Navbar from './Navbar';
import Footer from './Footer';
import DOMPurify from 'dompurify';

const PublicationDetail = () => {
    const { id } = useParams();
    const [publication, setPublication] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPublication = async () => {
            const db = getFirestore();

            try {
                const docRef = doc(db, 'publications', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setPublication(docSnap.data());
                } else {
                    setError('Publication introuvable');
                }
            } catch (error) {
                setError('Erreur lors de la récupération de la publication');
            } finally {
                setLoading(false);
            }
        };

        fetchPublication();
    }, [id]);

    // Sanitize the publication content
    const sanitizedContent = publication ? DOMPurify.sanitize(publication.content) : '';

    return (
        <div className="publication-detail d-flex flex-column min-vh-100">
            <Navbar active="publications" />
            <Container className="flex-grow-1 mt-4  main-content">
                {loading && <Spinner animation="border" variant="light" />}
                {error && <Alert variant="danger">{error}</Alert>}
                {publication && (
                    <div>
                        <h1>{publication.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                    </div>
                )}
            </Container>
            <Footer active="publications" />
        </div>
    );
};

export default PublicationDetail;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { Button } from 'react-bootstrap'; // Make sure to import Bootstrap components

const AdminPortail = () => {
    const { email } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCases = async () => {
            const db = getFirestore();

            try {
                const casesRef = collection(db, 'users', email, 'cases');
                const q = query(casesRef);
                const querySnapshot = await getDocs(q);

                const fetchedCases = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setCases(fetchedCases);
            } catch (error) {
                setError('Failed to fetch cases');
            } finally {
                setLoading(false);
            }
        };

        fetchCases();
    }, [email]);

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const [year, month, day] = dateStr.split('-');
        return `${day}/${month}/${year}`;
    };

    const filteredCases = cases.filter(c => c.id.toString().includes(searchTerm));

    return (
        <div className="container mt-4 text-white">
            <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h2 className="mb-0">Liste des Affaires de {email}</h2>
                    <p className="mb-0">Cliquez sur le numéro de dossier pour accéder aux différentes écritures de la procédure.</p>
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="search" className="mr-2 align-self-center mb-0">Recherche par numéro de dossier</label>
                    <input
                        type="text"
                        id="search"
                        className="form-control"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Numéro de dossier"
                    />
                </div>
            </div>

            {loading && <p>Chargement...</p>}
            {error && <p>{error}</p>}

            {!loading && !error && (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => navigate(`/admin/create-case/${email}`)}
                        className="mb-4"
                    >
                        Créer un nouveau dossier
                    </Button>
                    <table className="table table-dark my-table table-borderless">
                        <thead>
                        <tr>
                            <th>Numéro de dossier</th>
                            <th>Nom des Parties en cause</th>
                            <th>Jurisdiction</th>
                            <th>État de la procédure</th>
                            <th>Prochaine audience</th>
                            <th>Actions</th> {/* New Actions column */}
                        </tr>
                        </thead>
                        <tbody>
                        {filteredCases.map((affaire) => (
                            <tr key={affaire.id}>
                                <td>
                                    <Link
                                        to={`/admin/ecritures/${email}/${affaire.id}`}
                                        style={{ color: '#1F3D5F', fontWeight: 'bold' }}
                                    >
                                        {affaire.id}
                                    </Link>
                                </td>
                                <td>{affaire.parties}</td>
                                <td>{affaire.jurisdiction}</td>
                                <td>{affaire.status}</td>
                                <td>{formatDate(affaire.nextHearing)}</td>
                                <td>
                                    <Button
                                        variant="dark"
                                        size="sm"
                                        onClick={() => navigate(`/admin/edit-case/${email}/${affaire.id}`)}
                                    >
                                        Modifier
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default AdminPortail;

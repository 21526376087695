import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { Button, Container, Form, Spinner, Alert } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditPublication = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [preview, setPreview] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPublication = async () => {
            const db = getFirestore();

            try {
                const docRef = doc(db, 'publications', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const publication = docSnap.data();
                    setTitle(publication.title);
                    setContent(publication.content);
                    setPreview(publication.preview);
                } else {
                    setError('Publication introuvable');
                }
            } catch (error) {
                setError('Erreur lors de la récupération de la publication');
            } finally {
                setLoading(false);
            }
        };

        fetchPublication();
    }, [id]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const db = getFirestore();

        try {
            const docRef = doc(db, 'publications', id);
            await updateDoc(docRef, {
                title,
                content,
                preview
            });
            navigate('/admin/publications');
        } catch (error) {
            setError('Erreur lors de la mise à jour de la publication');
        }
    };

    return (
        <Container className="mt-4 text-white">
            {loading && <Spinner animation="border" variant="light" />}
            {error && <Alert variant="danger">{error}</Alert>}

            {!loading && !error && (
                <Form onSubmit={handleUpdate}>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Titre</Form.Label>
                        <Form.Control
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formPreview" className="mt-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={preview}
                            onChange={(e) => setPreview(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formContent" className="mt-3">
                        <Form.Label>Contenu</Form.Label>
                        <div className="text-black">
                            <CKEditor
                                editor={ClassicEditor}
                                data={content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                }}
                                config={{
                                    contentsCss: {
                                        body: {
                                            color: '#000000',
                                        }
                                    },
                                }}
                            />
                        </div>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="mt-4">
                        Mettre à jour la publication
                    </Button>
                </Form>
            )}
        </Container>
    );
};

export default EditPublication;

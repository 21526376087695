import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Container, Form, Button, Alert } from 'react-bootstrap';

const CreatePublication = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState(''); // New state for description
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!title || !content || !description || !image) {
            setError('All fields are required.');
            return;
        }

        try {
            // Upload image to Firebase Storage
            const storageRef = ref(storage, `publications/${image.name}`);
            await uploadBytes(storageRef, image);
            const imageUrl = await getDownloadURL(storageRef);

            // Add publication to Firestore
            await addDoc(collection(db, 'publications'), {
                title,
                content,
                preview: description, // Save description as preview
                image: imageUrl
            });

            setTitle('');
            setContent('');
            setDescription('');
            setImage(null);
            setPreview('');
            setSuccess('Publication créée.');
        } catch (err) {
            console.error('Error creating publication:', err);
            setError('Failed to create publication.');
        }
    };

    return (
        <Container className='text-white mb-5'>
            <h2>Créer une publication</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="title" className="mb-3">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="description" className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="content" className="mb-3">
                    <Form.Label>Contenu</Form.Label>
                    <div className="text-black">
                        <CKEditor
                            editor={ClassicEditor}
                            data={content}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(data);
                            }}
                            config={{
                                contentsCss: {
                                    body: {
                                        color: '#000000',
                                    }
                                },
                            }}
                        />
                    </div>
                </Form.Group>
                <Form.Group controlId="image" className="mb-3">
                    <Form.Label>Image</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={handleImageChange}
                        required
                    />
                </Form.Group>
                {preview && (
                    <div className="mb-3">
                        <img src={preview} alt="Preview" style={{ width: '100px', height: 'auto' }} />
                    </div>
                )}
                <Button type="submit" variant="primary">Créer</Button>
            </Form>
        </Container>
    );
};

export default CreatePublication;

import React from 'react';
import './Home.css';
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div className="container-fluid vh-100">
            <div className="row h-100">
                <div className="col-lg-6 col-12 d-lg-flex d-none justify-content-center align-items-center bg-grey">
                    <img src="/images/white-transparent-bg.png" alt="Centered Pic" className="img-fluid" />
                </div>
                <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center bg-image text-center">
                    <Link to="/cabinet"><button className="btn custom-button mb-3">LE CABINET</button></Link>
                    <Link to="/publications"><button className="btn custom-button mb-3">PUBLICATIONS</button></Link>
                    <Link to="/contacts"><button className="btn custom-button mb-3">CONTACTS</button></Link>
                    <Link to="/rendez-vous"><button className="btn custom-button mb-3">PRENDRE RENDEZ-VOUS</button></Link>
                    <Link to="/login"><button className="btn custom-button mb-3">ESPACE CLIENT</button></Link>
                </div>
            </div>
        </div>
    );
};

export default Home;

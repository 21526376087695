import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import {getStorage, ref, listAll, getDownloadURL, deleteObject, uploadBytes} from 'firebase/storage'; // Import deleteObject

const EcrituresAdmin = () => {
    const { id, email } = useParams(); // Get the case ID and user email from URL params
    const navigate = useNavigate();
    const [caseDetails, setCaseDetails] = useState({});
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    useEffect(() => {
        const fetchCaseDetailsAndDocuments = async () => {
            const db = getFirestore();
            const storage = getStorage();
            try {
                // Fetch case details for the specific user
                const caseDocRef = doc(db, 'users', email, 'cases', id);
                const caseDocSnap = await getDoc(caseDocRef);

                if (caseDocSnap.exists()) {
                    setCaseDetails(caseDocSnap.data());
                } else {
                    setError('Case not found');
                    setLoading(false);
                    return;
                }

                // Fetch document names from Firebase Storage
                const documentsRef = ref(storage, `users/${email}/cases/${id}`);
                const documentList = await listAll(documentsRef);
                const fetchedDocuments = await Promise.all(documentList.items.map(async (itemRef) => {
                    const fileUrl = await getDownloadURL(itemRef);
                    return {
                        title: itemRef.name,
                        file: fileUrl,
                        ref: itemRef // Store the reference to delete the document
                    };
                }));

                setDocuments(fetchedDocuments);
            } catch (error) {
                setError('Failed to fetch case details or documents');
            } finally {
                setLoading(false);
            }
        };

        fetchCaseDetailsAndDocuments();
    }, [email, id]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) return;
        const storage = getStorage();
        const fileRef = ref(storage, `users/${email}/cases/${id}/${file.name}`);
        setUploading(true);
        setUploadError(null);

        try {
            await uploadBytes(fileRef, file);
            const fileUrl = await getDownloadURL(fileRef);
            setDocuments([...documents, { title: file.name, file: fileUrl, ref: fileRef }]);
            setFile(null); // Reset file input
            setUploading(false);
        } catch (error) {
            setUploadError('Error uploading file: ' + error.message);
            setUploading(false);
        }
    };

    const handleDelete = async (docRef) => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce document?');
        if (confirmDelete) {
            try {
                await deleteObject(docRef);
                setDocuments(documents.filter(doc => doc.ref !== docRef)); // Remove from state
            } catch (error) {
                alert('Erreur lors de la suppression du document: ' + error.message);
            }
        }
    };

    if (loading) {
        return <p className="text-white">Chargement...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="container mt-4 text-white">
            <div className="d-flex justify-content-end mb-3">
                <button
                    className="btn btn-secondary"
                    onClick={() => navigate('/admin')}
                >
                    Retour à la Liste des Affaires
                </button>
            </div>
            <p className="mb-3">
                Cliquez sur le numéro de dossier pour accéder aux différentes écritures de la procédure.
            </p>
            <div className="light-box">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <h2 className="mb-0 me-3" style={{ color: '#1F3D5F', fontWeight: 'bold' }}>{caseDetails.id}</h2>
                        <h4 className="mb-0">{caseDetails.parties}</h4>
                    </div>
                    <div className="d-flex align-items-center ">
                        <input type="file" onChange={handleFileChange} className="me-2" />
                        <button
                            className="btn btn-dark"
                            onClick={handleUpload}
                            disabled={uploading}
                        >
                            {uploading ? 'Importation...' : 'Importer'}
                        </button>
                        {uploadError && <p className="text-danger mt-2">{uploadError}</p>}
                    </div>
                </div>

                <p className="mb-3 mt-5">
                    * Cliquez sur l'icône du fichier que vous souhaitez télécharger
                </p>
                <div className="d-flex flex-wrap">
                    {documents.map((doc, index) => (
                        <div key={index} className="text-center m-3">
                            <a href={doc.file} target="_blank" rel="noopener noreferrer">
                                <img src="/images/pdf.png" alt={doc.title} style={{ width: '100px' }} />
                            </a>
                            <p className="mt-2">{doc.title}</p>
                            <button
                                className="btn btn-danger"
                                onClick={() => handleDelete(doc.ref)}
                            >
                                <i className="bi bi-trash"></i> Supprimer
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EcrituresAdmin;

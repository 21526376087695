import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import emailjs from '@emailjs/browser';

import { initializeApp } from 'firebase/app';
import { UserProvider } from './components/UserContext'; // Import UserProvider

const firebaseConfig = {
    apiKey: "AIzaSyDG7sAp_lQttxpNMXuoYPFydml7PXlrvd8",
    authDomain: "iaa-avocats.firebaseapp.com",
    projectId: "iaa-avocats",
    storageBucket: "iaa-avocats.appspot.com",
    messagingSenderId: "767248670466",
    appId: "1:767248670466:web:6ad5089db5c6dba85d6c1b",
    measurementId: "G-FEP85WFZ27"
};

initializeApp(firebaseConfig);
emailjs.init({
    publicKey: "CE09rkVplwTkqN17B",
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <UserProvider> {/* Wrap the App component with UserProvider */}
            <App />
        </UserProvider>
    </React.StrictMode>
);

reportWebVitals();

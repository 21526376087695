import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Navbar from './Navbar';

const Layout = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        const fetchUserDetails = async (email) => {
            try {
                // Check if user is an admin
                const adminDoc = await getDoc(doc(db, 'admins', email));
                if (adminDoc.exists()) {
                    setUserRole('admin');
                    setUserName(adminDoc.data().name);
                } else {
                    // Fetch user name from users collection
                    const userDoc = await getDoc(doc(db, 'users', email));
                    if (userDoc.exists()) {
                        setUserRole('user');
                        setUserName(userDoc.data().name);
                    }
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const email = user.email;
                setUser(email);
                fetchUserDetails(email);
            } else {
                setUser(null);
                setUserName('');
                setUserRole('');
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        // Redirect logic for unauthorized access
        if (userRole === 'user' && window.location.pathname.startsWith('/admin')) {
            navigate('/login');
        } else if (userRole === 'admin' && (window.location.pathname === '/portail' || window.location.pathname.startsWith('/ecritures/'))) {
            navigate('/login');
        }
    }, [userRole, navigate]);

    if (user === null) {
        // Show loading state or null while checking auth status
        return null;
    }

    return (
        <div>
            <Navbar userEmail={user} userName={userName} userRole={userRole} />
            <div className="container mt-4">
                {/* Render the child components and pass the userEmail, userName, and userRole props directly */}
                {React.Children.map(children, child =>
                    React.cloneElement(child, { userEmail: user, userName, userRole })
                )}
            </div>
        </div>
    );
};

export default Layout;

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Publications = () => {
    const [publications, setPublications] = useState([]);
    const [focusPublication, setFocusPublication] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPublications = async () => {
            const db = getFirestore();
            const publicationsCollection = collection(db, 'publications');
            const publicationsSnapshot = await getDocs(publicationsCollection);
            const publicationsList = publicationsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPublications(publicationsList);

            if (publicationsList.length > 0) {
                setFocusPublication(publicationsList[publicationsList.length - 1]); // Set the last publication as the focus
            }
        };

        fetchPublications();
    }, []);

    const handleReadMore = (id) => {
        navigate(`/publications/${id}`);
    };

    return (
        <>
            <Navbar active="publications" />

            <div className="hero-section  main-content">
                {focusPublication && (
                    <Container>
                        <div className="hero-content">
                            <h2>Focus</h2>
                            <h1>{focusPublication.title}</h1>
                            <p>{focusPublication.preview}</p>
                            <Button variant="warning" className="text-dark"
                                    onClick={() => handleReadMore(focusPublication.id)}>LIRE LA SUITE</Button>
                        </div>
                    </Container>
                )}
            </div>

            <div className="publications-section">
                <Container>
                    <h2 className="publications-title">Les dernières publications</h2>
                    {publications.length > 0 && (
                        publications.slice(0, -1).map(publication => ( // Exclude the last publication from this list
                            <Row key={publication.id}>
                                <Col md={6}>
                                    <div className="publication">
                                        <h3>{publication.title}</h3>
                                        <p>{publication.preview}</p>
                                        <Button onClick={() => handleReadMore(publication.id)}>LIRE LA SUITE</Button>
                                    </div>
                                </Col>
                            </Row>
                        ))
                    )}
                    {publications.length === 0 && <p>Aucune publication disponible.</p>}
                </Container>
            </div>

            <Footer active="publications" />
        </>
    );
};

export default Publications;

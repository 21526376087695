import React from 'react';
import Footer from "./Footer";
import Navbar from "./Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Accordion} from 'react-bootstrap';

const Cabinet = () => {
    return (
        <>
            <Navbar active="le-cabinet"/>

            <div className="cabinet main-content">
                <div className="cabinet-container">
                    <div className="cabinet-content">
                        <h2 className="cabinet-title TheMelancholy">Le cabinet</h2>
                        <p className="cabinet-description">
                            Le cabinet ITIBAR & AVOCATS ASSOCIES, situé à Moroni, Union des Comores, est un cabinet de
                            premier plan spécialisé en droit des affaires. Dirigé par l'avocat Aïcham Itibar, le cabinet
                            est réputé pour son expertise dans les transactions internationales, offrant des services
                            juridiques de qualité à une clientèle locale et internationale <exigeante
                            className=""></exigeante>
                        </p>
                    </div>
                </div>
                <Container fluid className="mt-5">
                    <Row className="reputation-section">
                        <Col md={6}>
                            <div className="reputation-text pb-0">
                                <h3>Réputation et Classements :</h3>
                                <p>
                                    ITIBAR & AVOCATS ASSOCIES est l'un des meilleurs cabinets d'avocats en droit des
                                    affaires aux Comores. Il est reconnu pour son expertise en conseil juridique et en
                                    résolution de litiges complexes, avec une position privilégiée dans les classements
                                    internationaux, notamment par Chambers and Partners. Le cabinet est particulièrement
                                    réputé pour ses compétences dans les secteurs de l'énergie, des télécommunications,
                                    et de l'aviation.
                                </p>
                            </div>
                        </Col>
                        <Col md={6} className="p-4">
                            <div className="top-ranked">
                                <img src="/images/top-ranked.png" alt="Top Ranked"
                                     className="img-fluid reputation-image"/>
                            </div>
                        </Col>
                    </Row>

                    <div className="text-center mt-5 nos-valeurs">
                        <h4 className="TheMelancholy">Nos valeurs</h4>
                    </div>
                    <div className="text-section text-center">
                        <p>
                            ITIBAR & AVOCATS ASSOCIES se distingue par son intégrité, sa proactivité, et son
                            professionnalisme. Le cabinet s'efforce de fournir des solutions juridiques précises et
                            adaptées aux défis complexes de ses clients, dans un environnement d'affaires en
                            constante évolution.
                        </p>
                        <p>
                            La réactivité est au cœur de sa pratique, permettant à ses avocats
                            de s'adapter rapidement et de proposer des stratégies juridiques innovantes. Le cabinet
                            s'engage à défendre les intérêts de ses clients avec rigueur, éthique irréprochable,
                            transparence, et communication claire.
                        </p>
                    </div>
                </Container>

                <div className="expertise-section">
                    <h2 className="expertise-title">Nos domaines d’expertise</h2>
                    <p>
                        ITIBAR & AVOCATS ASSOCIES se distingue par une approche rigoureuse et un engagement envers
                        l'excellence. Le cabinet est reconnu pour sa spécialisation en droit des affaires et offre une
                        large gamme de services juridiques couvrant plusieurs domaines d'expertise :
                    </p>
                    <Accordion className="custom-accordion my-4">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Droit des Sociétés</Accordion.Header>
                            <Accordion.Body>
                                Le cabinet accompagne ses clients dans les opérations de fusion et acquisition, les
                                restructurations, et la gouvernance d'entreprise, tout en assurant une conformité totale
                                aux
                                réglementations en vigueur. Il propose également des services de conseil en financement
                                et
                                structuration de capital.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Droit des Contrats</Accordion.Header>
                            <Accordion.Body>
                                Spécialisé dans l'élaboration et la négociation de contrats complexes, ITIBAR & AVOCATS
                                ASSOCIES
                                minimise les risques et protège les intérêts de ses clients dans des secteurs tels que
                                les
                                télécommunications, l'énergie, et les infrastructures.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Contentieux et Arbitrage</Accordion.Header>
                            <Accordion.Body>
                                Le cabinet offre une représentation efficace dans les litiges commerciaux et
                                internationaux,
                                avec une expertise notable en arbitrage, notamment dans les domaines de l'assurance et
                                de la
                                finance.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Droit Fiscal et Optimisation Fiscale</Accordion.Header>
                            <Accordion.Body>
                                ITIBAR & AVOCATS ASSOCIES propose des conseils en planification et optimisation fiscales
                                pour
                                maximiser les avantages tout en assurant une conformité stricte, incluant la fiscalité
                                internationale et les prix de transfert.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Partenariat Public-Privé et Droit des Investissements</Accordion.Header>
                            <Accordion.Body>
                                Le cabinet est expérimenté dans la structuration et la négociation de partenariats
                                public-privé
                                (PPP), offrant des conseils juridiques complets pour sécuriser les investissements et
                                assurer
                                une protection juridique adéquate.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Droit de la Propriété Intellectuelle</Accordion.Header>
                            <Accordion.Body>
                                ITIBAR & AVOCATS ASSOCIES protège et défend les actifs immatériels de ses clients,
                                incluant
                                brevets, marques, et droits d'auteur, en offrant également des services de gestion de
                                portefeuilles et de litiges en matière de contrefaçon.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Droit Bancaire et Financier</Accordion.Header>
                            <Accordion.Body>
                                Le cabinet conseille sur les aspects juridiques liés aux opérations bancaires,
                                financières, et
                                d'investissement, incluant les produits dérivés, les fonds d'investissement, et la
                                réglementation des marchés financiers.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Droit Immobilier</Accordion.Header>
                            <Accordion.Body>
                                ITIBAR & AVOCATS ASSOCIES offre des services de conseil en transactions immobilières,
                                développement foncier, et gestion immobilière, en assurant la conformité avec les lois
                                locales
                                et internationales.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <p className="mt-3">
                        Cette diversité de compétences permet au cabinet de répondre aux besoins variés de ses
                        clients et de fournir des solutions juridiques intégrées et sur mesure, en phase avec
                        les enjeux économiques contemporains.
                    </p>
                </div>

                {/* Notre équipe section */}
                <div className="team-section">
                    <h2 className="team-title pt-5">Notre équipe</h2>
                    <div className="row g-5 team-columns"> {/* Added g-4 for gap */}
                        <div className="col-md-4 team-column text-start"> {/* Added text-start for left alignment */}
                            <h3 className="nos-associes">Nos associés</h3>
                            <p>
                                Le cabinet ITIBAR & AVOCATS ASSOCIES est dirigé par deux associés éminents, Maître
                                Aïcham Itibar et Maître Moncef Saïd Ibrahim, qui apportent chacun une expertise
                                précieuse et complémentaire.
                            </p>
                            <p>
                                L'association de Maître Aïcham Itibar et de Maître Moncef Saïd Ibrahim forme une équipe
                                complémentaire, offrant une gamme complète de services juridiques de haute qualité.
                            </p>
                        </div>
                        <div className="col-md-4 team-column">
                            <div className="team-images">
                                <img src="/images/associe1.jpeg" alt="Member 1" className="img-fluid" style={{maxWidth: '230px'}}/>
                            </div>
                            <h4>Maître Aïcham Itibar :</h4>
                            <p className="justify">
                                Fondateur et associé principal du cabinet, Maître Aïcham Itibar est un avocat de renom
                                au Barreau de Moroni, spécialisé en droit des affaires avec une expertise particulière
                                en droit de l'OHADA. Il est également avocat agréé auprès de la Cour Suprême des
                                Comores, ce qui lui permet de représenter ses clients devant la plus haute juridiction
                                du pays. Reconnu pour sa maîtrise des fusions et acquisitions, des restructurations
                                d'entreprises, du contentieux commercial, ainsi que pour ses compétences en arbitrage
                                international, Maître Itibar est un leader dans son domaine grâce à son approche
                                stratégique et sa capacité à fournir des conseils juridiques adaptés aux besoins
                                complexes des clients.
                            </p>
                        </div>
                        <div className="col-md-4 team-column">
                            <div className="team-images">
                                <img src="/images/associe2.jpeg" alt="Member 2" className="img-fluid" style={{maxWidth: '230px'}}/>
                            </div>
                            <h4>Maître Moncef Saïd Ibrahim :</h4>
                            <p className="justify">
                                En tant que co-associé, Maître Moncef Saïd Ibrahim est spécialisé en droit fiscal et en
                                droit des investissements. Il est particulièrement expert dans la structuration de
                                partenariats public-privé (PPP) et l'optimisation fiscale, offrant des solutions
                                innovantes pour maximiser les avantages fiscaux tout en assurant une stricte conformité.
                                Maître Saïd Ibrahim possède également une expérience notable en droit bancaire et
                                financier, aidant les clients à naviguer dans les régulations complexes des marchés
                                financiers.
                            </p>
                        </div>
                    </div>
                </div>


                {/* Nos collaborateurs section */}
                <div className="collaborators-section pb-5">
                    <h2 className="nos-associes rem10 mb-4">Nos collaborateurs</h2>
                    <div className="collaborators-grid">
                        <div className="collaborator">
                            <img src="/images/collaborateur1.jpeg" alt="Maître Ibrahim CAMAR-EDDINE"/>
                            <div className="collaborator-name">Maître Ibrahim CAMAR-EDDINE</div>
                            <div className="collaborator-role">Avocat</div>
                        </div>
                        <div className="collaborator">
                            <img src="/images/collaborateur2.jpeg" alt="Maître Fahad NOURDINE"/>
                            <div className="collaborator-name">Maître Fahad NOURDINE</div>
                            <div className="collaborator-role">Avocat</div>
                        </div>
                        <div className="collaborator">
                            <img src="/images/collaborateur3.jpeg" alt="Maître Nezif-Hadj IBRAHIM"/>
                            <div className="collaborator-name">Maître Nezif-Hadj IBRAHIM</div>
                            <div className="collaborator-role">Avocat</div>
                        </div>
                        <div className="collaborator">
                            <img src="/images/collaborateur4.jpeg" alt="Mme Farahati SAADI"/>
                            <div className="collaborator-name">Mme Farahati SAADI</div>
                            <div className="collaborator-role">Assistance juridique</div>
                        </div>
                        <div className="collaborator">
                            <img src="/images/collaborateur5.jpeg" alt="Mme Zalhata MAHAMOUD"/>
                            <div className="collaborator-name">Mme Zalhata MAHAMOUD</div>
                            <div className="collaborator-role">Secrétaire juridique</div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer active="le-cabinet"/>
        </>
    );
};

export default Cabinet;

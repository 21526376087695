import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
import emailjs from '@emailjs/browser';
import { Button, Form } from 'react-bootstrap';

const jurisdictions = [
    'JEX civil', 'JEX commercial', 'Référé TPI', 'Référé CA', 'Référé TC', 'Référé CAC',
    '1er Président de CA', 'Juge des Requêtes TPI', 'Juge des Requêtes TC', 'Juge cadial',
    'Procureur', 'Juge d\'Instruction', 'Chambre d\'accusation', 'Tribunal de Première Instance',
    'Tribunal de Commerce', 'Tribunal du Travail', 'Tribunal Administratif', 'Cour d\'Arbitrage',
    'Juge des Enfants', 'Tribunal Correctionnel', 'Cour d\'Assises', 'Juridiction d\'Exception',
    'Cour d\'Appel de droit commun', 'Cour d\'Appel Commercial', 'Cour Administrative d\'Appel',
    'Cour Suprême', 'Haut Conseil de la Magistrature', 'Conseil de Discipline du Barreau',
    'Conseil de Discipline Professionnelle', 'Inspection du Travail', 'Autre'
];

const procedureStatuses = [
    'Attente de la convocation', 'Attente du premier appel de cause', 'Attente des écritures de la partie adverse',
    'Attente de transmission de pièces de la partie adverse', 'Écriture à produire avant la prochaine audience',
    'Pièces à produire avant la prochaine audience', 'Affaire mise en délibéré', 'Affaire clôturée – gain de cause',
    'Affaire clôturée – perte de la cause – attente d\'introduction de voies de recours',
    'Attente d\'enrôlement de l\'affaire', 'Écriture à soumettre à la juridiction',
    'Proposition d\'accord amiable à soumettre à l\'autre partie', 'Réponse à proposition d\'accord amiable à émettre',
    'Attente de la rédaction de la décision', 'Décision transmise à l\'huissier pour exécution',
    'Décision remise au client', 'Déportation de l\'affaire, dossier remis au client',
    'Désistement à produire', 'Protocole transactionnel à faire signer', 'Attente des instructions du client',
    'Voies de recours à introduire'
];

const CreateCase = () => {
    const navigate = useNavigate();
    const { email } = useParams();
    const [newCase, setNewCase] = useState({
        id: '',
        parties: '',
        jurisdiction: '',
        status: '',
        nextHearing: '',
    });
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState(null);

    const formatDate = (dateStr) => {
        // Convert "yyyy-mm-dd" to "dd/mm/yyyy"
        if (!dateStr) return '';
        const [year, month, day] = dateStr.split('-');
        return `${day}/${month}/${year}`;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCase((prevState) => ({
            ...prevState,
            [name]: name === 'nextHearing' ? value : value,
        }));
    };

    const sendEmailNotification = async () => {
        try {
            const templateParams = {
                user_email: email,
                case_id: newCase.id,
                parties: newCase.parties,
                jurisdiction: newCase.jurisdiction,
                status: newCase.status,
                nextHearing: formatDate(newCase.nextHearing),
            };

            await emailjs.send(
                'service_7lz79yj',
                'template_n7h00co',
                templateParams
            );
            console.log('Email sent successfully');
        } catch (error) {
            console.error('Failed to send email:', error);
            setEmailError('Failed to send email notification');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCreating(true);
        try {
            const db = getFirestore();
            const userCasesRef = collection(db, 'users', email, 'cases');
            const newCaseDocRef = doc(userCasesRef, newCase.id);

            await setDoc(newCaseDocRef, {
                ...newCase,
                nextHearing: newCase.nextHearing,
            });
            await sendEmailNotification(); // Send the email notification
            if (!emailError) {
                navigate(`/admin/ecritures/${email}/${newCase.id}`);
            }
        } catch (error) {
            setError('Failed to create new case');
        } finally {
            setCreating(false);
        }
    };

    return (
        <div className="container mt-4 text-white">
            <h2 className="mb-4">Créer un nouveau dossier</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>ID du Dossier</Form.Label>
                    <Form.Control
                        type="text"
                        name="id"
                        value={newCase.id}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Nom des Parties en cause</Form.Label>
                    <Form.Control
                        type="text"
                        name="parties"
                        value={newCase.parties}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Jurisdiction</Form.Label>
                    <Form.Select
                        name="jurisdiction"
                        value={newCase.jurisdiction}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Sélectionnez la jurisdiction</option>
                        {jurisdictions.map((jurisdiction, index) => (
                            <option key={index} value={jurisdiction}>
                                {jurisdiction}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>État de la procédure</Form.Label>
                    <Form.Select
                        name="status"
                        value={newCase.status}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Sélectionnez l'état de la procédure</option>
                        {procedureStatuses.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Prochaine audience</Form.Label>
                    <Form.Control
                        type="date"
                        name="nextHearing"
                        value={newCase.nextHearing}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>

                {error && <p className="text-danger">{error}</p>}
                {emailError && <p className="text-danger">{emailError}</p>}

                <Button variant="primary" type="submit" disabled={creating}>
                    {creating ? 'Création...' : 'Créer le dossier'}
                </Button>
            </Form>
            <Button
                variant="secondary"
                className="mt-3"
                onClick={() => navigate(`/admin/`)}
            >
                Annuler
            </Button>
        </div>
    );
};

export default CreateCase;

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { UserContext } from '../components/UserContext';

const Portail = () => {
    const userEmail = useContext(UserContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!userEmail) {
            return;
        }

        const fetchCases = async () => {
            const db = getFirestore();

            try {
                const casesRef = collection(db, 'users', userEmail, 'cases');
                const q = query(casesRef);
                const querySnapshot = await getDocs(q);

                const fetchedCases = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setCases(fetchedCases);
            } catch (error) {
                setError('Failed to fetch cases');
            } finally {
                setLoading(false);
            }
        };

        fetchCases();
    }, [userEmail]);

    if (!userEmail) {
        return <p className="text-white">Chargement...</p>;
    }

    const filteredCases = cases.filter(c => c.id.toString().includes(searchTerm));

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const [year, month, day] = dateStr.split('-');
        return `${day}/${month}/${year}`;
    };
    
    return (
        <div className="container mt-4 text-white">
            <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h2 className="mb-0">Liste des Affaires</h2>
                    <p className="mb-0">Cliquez sur le numéro de dossier pour accéder aux différentes écritures de la procédure.</p>
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="search" className="mr-2 align-self-center mb-0">Recherche par numéro de dossier</label>
                    <input
                        type="text"
                        id="search"
                        className="form-control"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Numéro de dossier"
                    />
                </div>
            </div>

            {loading && <p>Chargement...</p>}
            {error && <p>{error}</p>}

            {!loading && !error && (
                <table className="table table-dark my-table table-borderless">
                    <thead>
                    <tr>
                        <th>Numéro de dossier</th>
                        <th>Nom des Parties en cause</th>
                        <th>Jurisdiction</th>
                        <th>État de la procédure</th>
                        <th>Prochaine audience</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredCases.map((affaire) => (
                        <tr key={affaire.id}>
                            <td>
                                <Link
                                    to={`/ecritures/${affaire.id}`}
                                    state={{ caseDetails: affaire }} // Passing case details
                                    style={{ color: '#1F3D5F', fontWeight: 'bold' }}
                                >
                                    {affaire.id}
                                </Link>
                            </td>
                            <td>{affaire.parties}</td>
                            <td>{affaire.jurisdiction}</td>
                            <td>{affaire.status}</td>
                            <td>{formatDate(affaire.nextHearing)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Portail;

import React, { useState } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function CreateUser() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

    const handleCreateUser = async () => {
        const actionCodeSettings = {
            url: `${frontendUrl}/finishSignUp`, // Change this URL to match your deployment URL
        handleCodeInApp: true,
    };

        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignUp', email);

            await setDoc(doc(db, 'users', email), {
                email: email,
                name: name, // Save the name
                createdAt: new Date().toISOString(), // Save the creation timestamp
            });

            setSuccess('Un e-mail a été envoyé pour finaliser l\'inscription.');
            setEmail('');
            setName(''); // Clear name input
        } catch (error) {
            let errorMessage = '';
            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = 'Adresse e-mail invalide.';
                    break;
                case 'auth/missing-email':
                    errorMessage = 'Adresse e-mail manquante.';
                    break;
                default:
                    errorMessage = 'Une erreur est survenue. Veuillez réessayer.';
                    console.error(error);
            }
            setError(errorMessage);
        }
    };

    return (
        <div className="container my-5">
            <div className="p-4 light-box shadow rounded text-white">
                <h2 className="text-center mb-4">Créer un utilisateur</h2>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                {success && <div className="alert alert-success" role="alert">{success}</div>}
                <div className="mb-3">
                    <label className="form-label" htmlFor="name">Nom</label>
                    <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Entrez le nom"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="email">Adresse Email</label>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Entrez l'adresse e-mail"
                    />
                </div>
                <button className="btn btn-dark" onClick={handleCreateUser}>
                    Envoyer le lien d'inscription
                </button>
            </div>
        </div>
    );
}

export default CreateUser;
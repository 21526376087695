import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';

const AdminList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deletingUser, setDeletingUser] = useState(''); // State for tracking the user being deleted

    useEffect(() => {
        const fetchAdmins = async () => {
            const db = getFirestore();

            try {
                const usersRef = collection(db, 'admins');
                const q = query(usersRef);
                const querySnapshot = await getDocs(q);

                const fetchedAdmins = querySnapshot.docs
                    .map(doc => ({
                        email: doc.id,
                        ...doc.data()
                    }));

                setAdmins(fetchedAdmins);
            } catch (error) {
                setError('Failed to fetch admins');
            } finally {
                setLoading(false);
            }
        };

        fetchAdmins();
    }, []);

    const handleDeleteUser = async (email) => {
        if (!window.confirm(`Are you sure you want to delete the user: ${email}?`)) {
            return;
        }

        setDeletingUser(email); // Set the email of the user being deleted

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-admin`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete user');
            }

            setAdmins(admins.filter(user => user.email !== email));
            alert(`User ${email} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('Failed to delete user. Please try again.');
        } finally {
            setDeletingUser(''); // Clear the loading state after the operation is complete
        }
    };

    const filteredAdmins = admins.filter(admin =>
        admin.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container mt-4 text-white">
            <Link to="/admin">
                <button className="btn btn-secondary mb-5 me-3">
                    Retour
                </button>
            </Link>

            <Link to="/admin/create-admin">
                <button className="btn btn-secondary mb-5">
                    Créer un administrateur
                </button>
            </Link>

            <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                    <h2 className="mb-0">Liste des Administrateurs</h2>
                    <p className="mb-0">Cliquez sur un administrateur pour voir ses affaires.</p>
                </div>
                <div className="form-group mb-0">
                    <label htmlFor="search" className="mr-2 align-self-center mb-0">Recherche par e-mail</label>
                    <input
                        type="text"
                        id="search"
                        className="form-control"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="E-mail"
                    />
                </div>
            </div>

            {loading && <p>Chargement...</p>}
            {error && <p>{error}</p>}

            {!loading && !error && (
                <table className="table table-dark my-table table-borderless">
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>E-mail</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredAdmins.map((admin) => (
                        <tr key={admin.email}>
                            <td>{admin.name}</td>
                            <td>
                                <Link
                                    to={`/admin/portail/${admin.email}`}
                                    style={{ color: '#1F3D5F', fontWeight: 'bold' }}
                                >
                                    {admin.email}
                                </Link>
                            </td>
                            <td>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteUser(admin.email)}
                                    disabled={deletingUser === admin.email} // Disable the button if the user is being deleted
                                >
                                    {deletingUser === admin.email ? 'Suppression...' : 'Supprimer'}
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminList;

import React from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';

const MyNavbar = ({active}) => {
    return (
        <>
            <Navbar bg="light" expand="lg" fixed="top">
                <Container>
                    <Navbar.Brand href="/cabinet">
                        <img src="/images/black-horizontal-transparent-bg.png" alt="MyBrand" height="40"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/cabinet"
                                      className={`footer-link ${active === 'le-cabinet' ? 'active' : ''}`}>LE
                                CABINET</Nav.Link>
                            <Nav.Link href="/publications"
                                      className={`footer-link ${active === 'publications' ? 'active' : ''}`}>PUBLICATIONS</Nav.Link>
                            <Nav.Link href="/contacts"
                                      className={`footer-link ${active === 'contacts' ? 'active' : ''}`}>CONTACTS</Nav.Link>
                            <Nav.Link href="/rendez-vous" className={`footer-link ${active === 'rdv' ? 'active' : ''}`}>PRENDRE
                                RENDEZ-VOUS</Nav.Link>
                            <Nav.Link href="/login"
                                      className={`footer-link ${active === 'espace-client' ? 'active' : ''}`}>ESPACE
                                CLIENT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default MyNavbar;

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51Px6CgIbXJ7xicMP7AxN3dKZ6baXMqC8ucSG1CSPJbVG3RwVmSnW8u5A46H5nSVKjo3ucpaoxjpqzsZ0I6aBBXtq00PUjw6d1a'); // replace with your Stripe test publishable key

const CheckoutForm = ({ formData, handleSuccessfulPayment }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Create PaymentMethod
        const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: formData.nom + ' ' + formData.prenom,
                email: formData.email,
            },
        });

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        // Call backend to create a payment intent
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-payment-intent`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                amount: formData.tarif.split(' ')[0] * 100, // Convert to smallest currency unit
                currency: 'eur', // Set the currency to EUR (or your preferred currency)
                paymentMethodId: paymentMethod.id,
            }),
        });

        const paymentIntent = await response.json();

        if (paymentIntent.error) {
            setError(paymentIntent.error);
            setLoading(false);
            return;
        }

        // Confirm the payment with Stripe, using the payment method ID
        const { error: confirmError } = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
            payment_method: paymentMethod.id, // Provide the payment method ID here
        });

        if (confirmError) {
            setError(confirmError.message);
        } else {
            handleSuccessfulPayment();
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            {error && <div className="alert alert-danger">{error}</div>}
            <Button type="submit" className="mt-3" disabled={!stripe || loading}>
                {loading ? 'Chargement...' : 'Payer'}
            </Button>
        </form>
    );
};

const ReservationForm = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [formData, setFormData] = useState({
        consultationType: 'Consultation en ligne par visioconférence',
        prestataire: 'Maître Aïcham ITIBAR',
        date: '',
        time: '',
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        tarif: '',
        paymentMethod: 'online',
    });

    const handleSubmit = () => {
        if (formData.paymentMethod === 'online') {
            setShowPaymentModal(true);
        } else {
            createCalendarEvent();
            setShowConfirmationModal(true);
        }
    };

    const handleSuccessfulPayment = () => {
        setShowPaymentModal(false);
        createCalendarEvent();
        setShowConfirmationModal(true);
    };

    const updateTarif = () => {
        const { consultationType, prestataire } = formData;
        let price = 0;

        if (consultationType === 'Consultation au cabinet') {
            if (prestataire.startsWith('Maître Aïcham ITIBAR') || prestataire.startsWith('Maître Moncef SAÏD IBRAHIM')) {
                price = 150;
            } else {
                price = 100;
            }
        } else if (consultationType === 'Consultation en ligne par visioconférence') {
            if (prestataire.startsWith('Maître Aïcham ITIBAR') || prestataire.startsWith('Maître Moncef SAÏD IBRAHIM')) {
                price = 120;
            } else {
                price = 80;
            }
        }

        setFormData((prevState) => ({
            ...prevState,
            tarif: `${price} euros pour 30 minutes`,
            paymentMethod: consultationType === 'Consultation en ligne par visioconférence' ? 'online' : prevState.paymentMethod,
        }));
    };

    const createCalendarEvent = () => {
        const event = {
            summary: `Consultation avec ${formData.prestataire}`,
            location: formData.consultationType === 'Consultation au cabinet' ? 'Cabinet' : 'En ligne',
            description: `Consultation juridique avec ${formData.prestataire}`,
            start: `${formData.date}T${formData.time}:00`,
            end: `${formData.date}T${formData.time}:30:00`,
        };

        const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${event.summary}
LOCATION:${event.location}
DESCRIPTION:${event.description}
DTSTART:${event.start.replace(/[-:]/g, '')}Z
DTEND:${event.end.replace(/[-:]/g, '')}Z
END:VEVENT
END:VCALENDAR`;

        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result.split(',')[1];
            sendEmail(base64data);
        };
    };

    const sendEmail = (icsBase64) => {
        const data = {
            from: '"Réservation Consultation Juridique" <contact@espaceclient.iaa-avocats.legal>',
            to: formData.email,
            cc: 'contact@espaceclient.iaa-avocats.legal',
            subject: 'Nouvelle Réservation de Consultation Juridique',
            html: `
                <p>Bonjour,</p>
                <p>Une consultation juridique a été réservée avec les détails suivants :</p>
                <ul>
                    <li>Type de consultation : ${formData.consultationType}</li>
                    <li>Prestataire : ${formData.prestataire}</li>
                    <li>Date : ${formData.date}</li>
                    <li>Heure : ${formData.time}</li>
                    <li>Nom : ${formData.nom}</li>
                    <li>Prénom : ${formData.prenom}</li>
                    <li>Email : ${formData.email}</li>
                    <li>Téléphone : ${formData.telephone}</li>
                    <li>Tarif : ${formData.tarif}</li>
                </ul>
                <p>Veuillez trouver ci-joint l'événement de calendrier à ajouter à votre agenda.</p>`,
            attachments: [
                {
                    filename: 'event.ics',
                    content: icsBase64,
                    encoding: 'base64',
                },
            ],
        };

        fetch(`${backendUrl}/send-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Email envoyé avec succès!');
                    setShowConfirmationModal(true);
                } else {
                    console.error("Échec de l'envoi de l'email.", response.statusText);
                }
            })
            .catch((error) => {
                console.error("Échec de l'envoi de l'email.", error);
            });
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    useEffect(() => {
        updateTarif();
    }, [formData.consultationType, formData.prestataire]);

    return (
        <div className="rdv">
            <Navbar active="rdv" />
            <div className="container main-content">
                <h2 className="contact-title pt-5">Prenez rendez-vous</h2>
                <Form id="reservationForm">
                    <Form.Group controlId="consultationType" className="my-3">
                        <Form.Label>Type de Consultation</Form.Label>
                        <Form.Control as="select" value={formData.consultationType} onChange={handleChange}>
                            <option value="Consultation en ligne par visioconférence">Consultation en ligne par
                                visioconférence
                            </option>
                            <option value="Consultation au cabinet">Consultation au cabinet</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="prestataire" className="my-3">
                        <Form.Label>Avocat Prestataire</Form.Label>
                        <Form.Control as="select" value={formData.prestataire} onChange={handleChange}>
                            <optgroup label="Avocats Associés">
                                <option value="Maître Aïcham ITIBAR">Maître Aïcham ITIBAR</option>
                                <option value="Maître Moncef SAÏD IBRAHIM">Maître Moncef SAÏD IBRAHIM</option>
                            </optgroup>
                            <optgroup label="Avocats Collaborateurs">
                                <option value="Maître Ibrahim CAMAR-EDDINE">Maître Ibrahim CAMAR-EDDINE</option>
                                <option value="Maître Fahad NOURDINE">Maître Fahad NOURDINE</option>
                                <option value="Maître Nezif-Hadj IBRAHIM">Maître Nezif-Hadj IBRAHIM</option>
                            </optgroup>
                        </Form.Control>
                    </Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="date" className="my-3">
                                <Form.Label>Date de Consultation</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={formData.date}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="time" className="my-3">
                                <Form.Label>Heure de Consultation</Form.Label>
                                <Form.Control
                                    type="time"
                                    value={formData.time}
                                    onChange={handleChange}
                                    step="1800"
                                    disabled={formData.timeDisabled}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="nom" className="my-3">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control type="text" value={formData.nom} onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="prenom" className="my-3">
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control type="text" value={formData.prenom} onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="email" className="my-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={formData.email} onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="telephone" className="my-3">
                                <Form.Label>Numéro de Téléphone</Form.Label>
                                <Form.Control type="tel" value={formData.telephone} onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="tarif" className="my-3">
                        <Form.Label>Tarif</Form.Label>
                        <Form.Control type="text" value={formData.tarif} disabled readOnly/>
                    </Form.Group>
                    <Form.Group controlId="paymentMethod" className="my-3">
                        <Form.Label>Méthode de Paiement</Form.Label>
                        <Form.Control as="select" value={formData.paymentMethod} onChange={handleChange}>
                            <option value="online">Carte bancaire</option>
                            {formData.consultationType === 'Consultation au cabinet' && (
                                <option value="onsite">Paiement sur place (consultation au cabinet uniquement)</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Button className="Soumettre" id="submitReservation" onClick={handleSubmit}>
                        Réserver
                    </Button>
                </Form>

                <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Paiement en ligne</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm formData={formData} handleSuccessfulPayment={handleSuccessfulPayment} />
                        </Elements>
                    </Modal.Body>
                </Modal>

                <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation de réservation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Votre consultation a été réservée avec succès.</p>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </div>
    );
};

export default ReservationForm;
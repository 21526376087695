import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function SetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email; // Get email from state

    const handlePasswordChange = async () => {
        if (password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas.');
            return;
        }

        if (email) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/set-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                });

                if (response.ok) {
                    setSuccess('Mot de passe mis à jour avec succès !');
                    setTimeout(() => navigate('/login'), 2000); // Redirect to login after a short delay
                } else {
                    const data = await response.json();
                    setError('Erreur lors de la mise à jour du mot de passe : ' + data.message);
                }
            } catch (error) {
                setError('Erreur lors de la mise à jour du mot de passe : ' + error.message);
            }
        } else {
            setError('Email non trouvé.');
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h1 className="card-title mb-4">Définir un mot de passe</h1>
                            {error && <div className="alert alert-danger" role="alert">{error}</div>}
                            {success && <div className="alert alert-success" role="alert">{success}</div>}
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Nouveau mot de passe</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Entrez un nouveau mot de passe"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Confirmer le mot de passe</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirmez le mot de passe"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <button
                                className="btn btn-dark w-100"
                                onClick={handlePasswordChange}
                            >
                                Définir le mot de passe
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SetPassword;

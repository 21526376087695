import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ userEmail, userName, userRole }) => {
    const auth = getAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut(auth).then(() => {
            console.log('User signed out');
            navigate('/login');
        }).catch((error) => {
            console.error('Error signing out:', error);
        });
    };

    const handleLogoClick = () => {
        // Redirect based on user role
        if (userRole === 'admin') {
            navigate('/admin');
        } else {
            navigate('/portail');
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center p-3" style={{ backgroundColor: '#20263E' }}>
            <div className="d-flex align-items-center">
                <img
                    src="/images/white-transparent-bg.png"
                    alt="ITIBAR & Avocats Associés"
                    style={{ height: '50px', cursor: 'pointer' }}
                    onClick={handleLogoClick} // Added click handler
                />
            </div>
            <div className="text-white">
                <span>
                    Bienvenue dans votre espace {userRole === 'admin' ? 'admin' : 'client'}{' '}
                    <strong>{userName ? userName : userEmail}</strong>{' '}
                </span>
                <button
                    onClick={handleLogout}
                    className="btn btn-link text-white ml-3 p-0 ms-4"
                    style={{ textDecoration: 'underline' }}
                >
                    Se déconnecter ici
                </button>
            </div>
        </div>
    );
};

export default Navbar;
